import './feedback.scss';

import React from 'react';

import Layout from 'components/layout/SplitLayout';

export default class FeedbackPage extends React.Component {
  render() {
    return (
      <Layout>
        <div className="FAQPage">
          <h1>We&rsquo;d Love Your Feedback</h1>

          <p>
            Answer a few questions below to get future updates and help improve
            the site.
          </p>

          <div id="mc_embed_signup">
            <form
              action="https://gmail.us4.list-manage.com/subscribe/post?u=cad518d4c39edb4677dc1d7d1&amp;id=4b78b574c7"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              className="FeedbackPage-form"
              target="_blank"
            >
              <div id="mc_embed_signup_scroll">
                <div className="mc-field-group">
                  <label htmlFor="mce-EMAIL" className="FeedbackPage-label">
                    Email Address <span className="asterisk">*</span>
                  </label>
                  <input
                    type="email"
                    name="EMAIL"
                    className="FeedbackPage-input required email"
                    id="mce-EMAIL"
                  />
                </div>
                <div className="mc-field-group">
                  <label htmlFor="mce-FNAME" className="FeedbackPage-label">
                    First Name <span className="asterisk">*</span>
                  </label>
                  <input
                    type="text"
                    name="FNAME"
                    className="FeedbackPage-input required"
                    id="mce-FNAME"
                  />
                </div>
                <div className="mc-field-group">
                  <label htmlFor="mce-LNAME" className="FeedbackPage-label">
                    Last Name <span className="asterisk">*</span>
                  </label>
                  <input
                    type="text"
                    name="LNAME"
                    className="FeedbackPage-input required"
                    id="mce-LNAME"
                  />
                </div>
                <div className="mc-field-group">
                  <label htmlFor="mce-ORG" className="FeedbackPage-label">
                    Organization
                  </label>
                  <input
                    type="text"
                    name="ORG"
                    className="FeedbackPage-input"
                    id="mce-ORG"
                  />
                </div>
                <div className="mc-field-group">
                  <label htmlFor="mce-ROLE" className="FeedbackPage-label">
                    Your Role{' '}
                  </label>
                  <input
                    type="text"
                    name="ROLE"
                    className="FeedbackPage-input"
                    id="mce-ROLE"
                  />
                </div>

                <div className="mc-field-group">
                  <label htmlFor="mce-FEATURES" className="FeedbackPage-label">
                    Are there features you would like on the site?{' '}
                  </label>
                  <textarea
                    name="FEATURES"
                    className="FeedbackPage-textarea"
                    id="mce-FEATURES"
                  />
                </div>

                <div className="mc-field-group">
                  <label htmlFor="mce-ADDFRMWRK" className="FeedbackPage-label">
                    What frameworks would you like to see on the site?{' '}
                  </label>
                  <textarea
                    name="ADDFRMWRK"
                    className="FeedbackPage-textarea"
                    id="mce-ADDFRMWRK"
                  />
                </div>

                <div className="mc-field-group">
                  <label htmlFor="mce-COMMENTS" className="FeedbackPage-label">
                    Additional Comments
                  </label>
                  <textarea
                    name="COMMENTS"
                    className="FeedbackPage-textarea"
                    id="mce-COMMENTS"
                  />
                </div>

                <div id="mce-responses" className="clear">
                  <div
                    className="response"
                    id="mce-error-response"
                    style={{ display: 'none' }}
                  ></div>
                  <div
                    className="response"
                    id="mce-success-response"
                    style={{ display: 'none' }}
                  ></div>
                </div>

                <div className="indicates-required">
                  <small>
                    <span className="asterisk">*</span> required field
                  </small>
                </div>

                {/*<!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
                <div
                  style={{ position: 'absolute', left: '-5000px' }}
                  aria-hidden="true"
                >
                  <input
                    type="text"
                    name="b_cad518d4c39edb4677dc1d7d1_4b78b574c7"
                    tabIndex={-1}
                  />
                </div>

                <input
                  type="submit"
                  value="Subscribe"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  className="FeedbackPage-button button"
                />
              </div>
            </form>
          </div>
        </div>
      </Layout>
    );
  }
}
