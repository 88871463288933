import './SplitLayout.scss';

import React from 'react';

import Layout from './Layout';

interface Props {
  children: React.ReactNode;
  sidebar?: React.ReactNode;
}

/**
 * Layout for pages with a main content section and a sidebar on the right.
 */
const SplitLayout: React.FC<Props> = (props) => {
  const { children, sidebar } = props;

  return (
    <Layout>
      <div className="SplitLayout">
        <div className="SplitLayout-content">{children}</div>

        {sidebar}
      </div>
    </Layout>
  );
};

export default SplitLayout;
